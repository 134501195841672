import { getReadableRoute } from "../../utils/routing/getReadableRoute"
import { BrandRouteType } from "../../utils/routing/types"
import { useBrand } from "../data/useBrand"
import { useDesignSystem } from "../data/useDesignSystem"
import { useVersion } from "../data/useVersion"
import { useWorkspace } from "../data/useWorkspace"

export function useBrandRoute(
  wsId: string | undefined,
  dsId: string | undefined,
  versionId: string | undefined,
  brandId: string | undefined
): BrandRouteType | undefined {
  const { data: workspace } = useWorkspace(wsId, false)
  const { data: designSystem } = useDesignSystem(dsId)
  const { data: version } = useVersion(dsId, versionId)
  const { data: brand } = useBrand(dsId, versionId, brandId)

  if (!workspace || !designSystem || !version || !brand) return undefined

  return getReadableRoute(workspace, designSystem, version, brand)
}
